import React from "react"
import getValue from "get-value"
import SEO from "@components/seo"
import ContentBuilder from "@components/content-builder"

const StandalonePage = ({ pageContext }) => {
  const document = getValue(pageContext, "page")
  const sections = getValue(pageContext, "page.contentBuilder");

  return (
    <>
      <SEO {...{ document }} />
      {sections && <ContentBuilder blocks={sections} />}
    </>
  )
}
export default StandalonePage
