import React from "react"
import BlogListItem from "./blog-list-item"

const BlogList = ({ entries }) => {
  if (!entries) {
    return (
      <div className="max-w-screen-xl mx-auto">
        <div className="px-4 pt-16 pb-20 bg-white sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="container relative mx-auto">
            <p className="text-center">No entries.</p>
          </div>
        </div>
      </div>
    )
  }
  
  return (
    <div className="max-w-screen-xl">
      <div className="pb-20 bg-white w-full">
          <div className="grid gap-16 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:col-gap-16 lg:row-gap-16">
            {entries.map((item, index) => (
              <BlogListItem {...{ index, ...item }} key={`blogItem-${index}`} />
            ))}
          </div>
      </div>
    </div>
  )
}

export default BlogList