const { projection } = require("./projection")

const siteSettingsQuery = `*[_type == "siteSettings"] {
  ${projection.siteSettings}
}[0]`

const productsQuery = `*[_type == "shopifyProduct" && enabled == true] | order(orderRank asc) {
  ${projection.product}
}`

const productsListingQuery = `{
  "nodes": *[_type == "shopifyProduct" && enabled == true && showInProductListing == true] | order(orderRank asc) {
    ${projection.productCard}
  }
}`

const productGroupsQuery = `{
  "nodes": *[_type == "productGroup"] | order(orderRank asc) {
    ${projection.productGroups}
  },
}`

const collectionsLandingPageQuery = `*[_type == 'simpleLandingPage' && _id == 'productsIndex'] {
  ${projection.collectionsLandingPage}
}`

const standalonePagesQuery = `*[_type == 'landingPage'] {
  ${projection.standalonePage}
}`

const newsletterBannerQuery = `*[_type == 'newsletterBanner'] {
  ...
}[0]`

const servicesPoliciesInfoQuery = `*[_type == 'servicesPoliciesInfo'][0] {
  _id,
  _type,
  items[] {
    ${projection.servicesPolicies}
  }
}`

const blogIndexQuery = `*[_type == 'simpleLandingPage' && _id == 'blogIndex'] {
  ${projection.blogIndex}
}`

const blogCategoriesQuery = `*[_type == 'category' && group == 'blog'] | order(date desc) {
  ${projection.blogAndResourcesCategories}
}`

const blogPostsQuery = `*[_type == 'blogPost' && enabled != false && slug.current != null] | order(date desc) {
  ${projection.blogPost}
}`

const resourceIndexQuery = `*[_type == 'simpleLandingPage' && _id == 'resourcesIndex'] {
  ${projection.resourceIndex}
}`

const resourceCategoriesQuery = `*[_type == 'category' && group == 'resources'] {
  ${projection.blogAndResourcesCategories}
}`

const resourcePostsQuery = `*[_type == 'resourcePost' && enabled != false && slug.current != null] | order(date desc) {
  ${projection.resourcePost}
}`

const footerDataQuery = `{
  ${projection.footerData}
}`

const headerDataQuery = `{
  ${projection.headerData}
}`

const sanityDiscountCodesQuery = `*[_type == 'discountCode'] {
  ${projection.sanityDiscountCode}
}`

exports.sanityQueries = {
  siteSettingsQuery,
  newsletterBannerQuery,
  servicesPoliciesInfoQuery,
  standalonePagesQuery,
  collectionsLandingPageQuery,
  productsListingQuery,
  productsQuery,
  productGroupsQuery,
  blogIndexQuery,
  blogCategoriesQuery,
  blogPostsQuery,
  resourceIndexQuery,
  resourceCategoriesQuery,
  resourcePostsQuery,
  headerDataQuery,
  footerDataQuery,
  sanityDiscountCodesQuery,
}
